
<template>
  <!-- 服务管理已下架 -->
  <div class="">
    <ax-table
      ref="logTable"
      :columns="columns"
      :show-search="true"
      :searchForm="searchForm"
      :isCard="true"
      :dataSourceParams="dataSourceParams"
      @pop-confirm="popConfirm"
      :toolActions="toolActions"
      :dataSourceApi="api.list"
      @add="openDialog"
      @action-column-click="actionColumnClick"
    >
    </ax-table>
    <addService ref="addService" @refresh="refresh"></addService>
  </div>
</template>
   
   <script>
const searchForm = [
{
    label: "服务名称",
    type: "",
    model: "name",
    options: {
      placeholder: "请输入服务名称",
    },
    formItem: {},
    col: { span: 6 },
  },
];
import api from "./api";
import { initGridFormData } from "@/common/tools";
import addService from "./components/addService.vue";
export default {
  components: { addService },
  data() {
    return {
      api,
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "服务项目",
            dataIndex: "name",
            ellipsis: true,
            key: 1,
            width: 100,
          },
          {
            title: "服务编号",
            dataIndex: "productSn",
            ellipsis: false,
            key: 2,
            width: 80,
          },
          {
            title: "服务费用",
            dataIndex: "price",
            ellipsis: true,
            key: 3,
            width: 100,
          },
          {
            title: "服务原价",
            dataIndex: "originalPrice",
            ellipsis: false,
            width: 100,
            key: 4,
          },
          {
            title: "排序",
            dataIndex: "sort",
            ellipsis: false,
            width: 80,
            key: 5,
          },
        ],
        true,
        {
          maxWidth: 100,
          actions: [
            {
              props: (record) => {
                return {
                  text: "编辑",
                  name: "edit",
                  type: "#556bff",
                  link: true,
                  // disabled: !(record.status === "1"),
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "delete",
                  type: "#f95a5a",
                  link: true,
                  popDisabled: false, //delete是false是禁用
                  // disabled: !(record.status === "1"),
                };
              },
            },
          ],
        }
      ),
      searchForm: initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 80,
      }),
      // 操作按钮
      toolActions: [{ name: "add", text: "新增" }],
      visible: false,
      dataSourceParams: {publishStatus:0},
    };
  },

  methods: {
    openDialog() {
      this.$refs.addService.openDialog({titles:'新增服务'});
    },
      // 操作
      async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 编辑
        case "edit":
        this.$refs.addService.openDialog({record:record,titles:'编辑服务'});
          break;
      }
    },
    // 表格刷新
    refresh() {
      this.$refs.logTable.getDataSource();
    },

    // 根据id删除
    async popConfirm({ record }) {
      try {
        const res = await api.dels(record.id);
        if (res.status === 200) {
          this.$message.success("删除成功");
          this.$refs.logTable.getDataSource();
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  created() {},

  mounted() {},
};
</script>
   <style lang='less' scoped>
</style>